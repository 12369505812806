<template>
  <c-box>
    <!-- Asupan Sekarang -->
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Asupan Serat Sekarang (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Asupan Serat Sekarang (gram)"
          height="62px"
          v-model="currentFiberIntake"
        />
      </c-input-group>
    </c-form-control>

    <!--Kebutuhan Asupan-->
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kebutuhan Asupan Serat (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="fiberIntakeNeeds"
          height="62px"
          placeholder="Masukkan Kebutuhan Asupan Serat (gram)"
        >
          <!--
          1. Perempuan Dewasa -> 32
          2. Laki-laki Dewasa -> 37
          3. Hamil Atau Menyusui -> 37
          4. Perempuan Lansia -> 25
          5. Laki-laki Lansia -> 25
          6. Perempuan Remaja -> 29
          7. Laki-Laki Remaja -> 34
          8. Anak Dibawah 3 tahun -> 19
          9. Anak 4 - 6 Tahun -> 20
          10. Anak 7 - 9 Tahun -> 23
          -->
          <option value="32">Perempuan Dewasa (32)</option>
          <option value="37">
            Laki-laki Dewasa / Ibu Hamil atau Menyusui (37)
          </option>
          <option value="25">Perempuan / Laki-laki Lansia (25)</option>
          <option value="29">Perempuan Remaja (29)</option>
          <option value="34">Laki-Laki Remaja (34)</option>
          <option value="19">Anak Dibawah 3 tahun (19)</option>
          <option value="20">Anak 4 - 6 Tahun (20)</option>
          <option value="23">Anak 7 - 9 Tahun (23)</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--% Kecukupan-->
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        % Kecukupan Serat
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan % Kecukupan Serat"
          height="62px"
          paddingRight="60px"
          v-model="fiberAdequancy"
        />
      </c-input-group>
    </c-form-control>

    <!--Status Serat-->
    <c-form-control v-if="withoutAdds === false" is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Status Serat
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="fiberStatus"
          height="62px"
          placeholder="Masukkan Status Serat"
        >
          <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
          <option value="less_intake">Asupanmu Kurang</option>
          <option value="normal_intake">Asupanmu Normal</option>
          <option value="excessive_intake">Asupanmu Berlebih</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Catatan-->
    <c-form-control v-if="withoutAdds === false" is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Catatan Asupan Serat
      </c-form-label>
      <textarea
        rows="3"
        v-chakra
        id="proteinIntakeNotes"
        placeholder="Masukkan Catatan Asupan Serat"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="fiberIntakeNotes"
      />
    </c-form-control>
  </c-box>
</template>

<script>
import {
  calculateKecukupanSerat,
  calculateStatus,
} from "@/utils/asupan-gizi-calculation";

export default {
  name: "serat-form",
  props: {
    withoutAdds: Boolean,
    value: {
      required: true,
      type: Object,
      _type: {
        currentFiberIntake: Number,
        fiberIntakeNeeds: Number,
        fiberAdequancy: Number,
        fiberStatus: String,
        fiberIntakeNotes: String,
      },
    },
  },
  data() {
    return {
      currentFiberIntake: this.value.currentFiberIntake,
      fiberIntakeNeeds: this.value.fiberIntakeNeeds,
      fiberAdequancy: this.value.fiberAdequancy,
      fiberStatus: this.value.fiberStatus,
      fiberIntakeNotes: this.value.fiberIntakeNotes,
    };
  },
  computed: {
    _adequancy() {
      let data = calculateKecukupanSerat({
        asupanSeratSekarang: +this.currentFiberIntake,
        kebutuhanAsupanSerat: +this.fiberIntakeNeeds,
      });

      return +((data * 100).toFixed(2))
    },
    _status() {
      return calculateStatus(this.fiberAdequancy / 100);
    },
    _value() {
      return {
        currentFiberIntake: this.currentFiberIntake,
        fiberIntakeNeeds: this.fiberIntakeNeeds,
        fiberAdequancy: this.fiberAdequancy,
        fiberStatus: this.fiberStatus,
        fiberIntakeNotes: this.fiberIntakeNotes,
      };
    },
  },
  watch: {
    value(val) {
      this.currentFiberIntake = val.currentFiberIntake;
      this.fiberIntakeNeeds = val.fiberIntakeNeeds;
      this.fiberAdequancy = val.fiberAdequancy;
      this.fiberStatus = val.fiberStatus;
      this.fiberIntakeNotes = val.fiberIntakeNotes;
    },
    _value: {
      deep: true,
      handler(val) {
        let adequancy = this._adequancy;
        let status = this._status;

        if (val.fiberAdequancy !== adequancy) {
          val.fiberAdequancy = adequancy;
        }
        if (val.fiberStatus !== status) {
          val.fiberStatus = status;
        }

        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped></style>
