<template>
  <c-box>
    <!-- Asupan Sekarang -->
    <c-form-control is-required :is-invalid="$v.currentEnergyIntake.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Asupan Energi Sekarang (kkal)
      </c-form-label>
      <c-input
        type="number"
        placeholder="Masukkan Asupan Energi Sekarang (kkal)"
        height="62px"
        v-model.number="currentEnergyIntake"
      />
    </c-form-control>

    <!--Kebutuhan Asupan-->
    <c-form-control is-required :is-invalid="$v.energyIntakeNeeds.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kebutuhan Asupan Energi (kkal)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Kebutuhan Asupan Energi  (kkal)"
          height="62px"
          v-model.number="energyIntakeNeeds"
        />
      </c-input-group>
    </c-form-control>

    <!--% Kecukupan-->
    <c-form-control is-required :is-invalid="$v.energyAdequancy.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        % Kecukupan Energi
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan % Kecukupan Energi"
          height="62px"
          paddingRight="60px"
          v-model.number="energyAdequancy"
        />
      </c-input-group>
    </c-form-control>

    <!--Status Energi-->
    <c-form-control v-if="withoutAdds === false" is-required :is-invalid="$v.energyStatus.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Status Energi
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="energyStatus"
          height="62px"
          placeholder="Masukkan Status Energi"
        >
          <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
          <option value="less_intake">Asupanmu Kurang</option>
          <option value="normal_intake">Asupanmu Normal</option>
          <option value="excessive_intake">Asupanmu Berlebih</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Catatan-->
    <c-form-control v-if="withoutAdds === false"
      is-required
      :is-invalid="$v.energyIntakeNotes.$invalid"
      marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Catatan Asupan Energi
      </c-form-label>
      <textarea
        rows="3"
        v-chakra
        id="proteinIntakeNotes"
        placeholder="Masukkan Catatan Asupan Energi"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="energyIntakeNotes"
      />
    </c-form-control>
  </c-box>
</template>

<script>
import {
  calculateKebutuhanAsupanEnergi,
  calculateKecukupanEnergi,
  calculateStatus,
} from "@/utils/asupan-gizi-calculation";
import equal from "fast-deep-equal";
import required from "vuelidate/lib/validators/required"

export default {
  name: "energi-form",
  props: {
    aktivitasFisik: Number,
    kebutuhanEnergi: Number,
    defisitKalori: Number,
    tambahanKalori: Number,
    withoutAdds: Boolean,
    value: {
      required: true,
      type: Object,
      _type: {
        currentEnergyIntake: Number,
        energyIntakeNeeds: Number,
        energyAdequancy: Number,
        energyStatus: String,
        energyIntakeNotes: String,
      },
    },
  },
  data() {
    return {
      currentEnergyIntake: null,
      energyIntakeNeeds: null,
      energyAdequancy: null,
      energyStatus: null,
      energyIntakeNotes: null,
    };
  },
  methods: {
  },
  computed: {
    // Kebutuhan Asupan Energi
    _energyIntakeNeeds() {
      return calculateKebutuhanAsupanEnergi({
        aktivitasFisik: this.aktivitasFisik,
        kebutuhanEnergi: this.kebutuhanEnergi,
        defisitKalori: this.defisitKalori,
        tambahanKalori: this.tambahanKalori,
      });
    },
    _energyAdequancy() {
      let data = calculateKecukupanEnergi({
        asupanEnergiSekarang: this.currentEnergyIntake,
        kebutuhanAsupanEnergi: this._energyIntakeNeeds ?? 0,
      });
      return parseFloat((data * 100).toFixed());
    },
    _energyStatus() {
      let percent = this._energyAdequancy / 100;
      return calculateStatus(percent);
    },
    _value() {
      return {
        currentEnergyIntake: this.currentEnergyIntake,
        energyIntakeNeeds: this.energyIntakeNeeds,
        energyAdequancy: this.energyAdequancy,
        energyStatus: this.energyStatus,
        energyIntakeNotes: this.energyIntakeNotes,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.currentEnergyIntake = val.currentEnergyIntake;
        this.energyIntakeNeeds = val.energyIntakeNeeds;
        this.energyAdequancy = val.energyAdequancy;
        this.energyStatus = val.energyStatus;
        this.energyIntakeNotes = val.energyIntakeNotes;

        this.energyIntakeNeeds = this._energyIntakeNeeds;
        this.energyAdequancy = this._energyAdequancy;
        this.energyStatus = this._energyStatus;
      },
    },
    _value: {
      deep: true,
      handler(val, old) {
        if (equal(val, old)) return;

        let energyIntakeNeeds = this._energyIntakeNeeds;
        let energyAdequancy = this._energyAdequancy;
        let energyStatus = this._energyStatus;
        val.energyIntakeNeeds = energyIntakeNeeds;
        val.energyAdequancy = energyAdequancy;
        val.energyStatus = energyStatus;

        this.$emit("input", val);
      },
    },
  },
  validations: {
    currentEnergyIntake: {required},
    energyIntakeNeeds: {required},
    energyAdequancy: {required},
    energyStatus: {required},
    energyIntakeNotes: {required},
  },
};
</script>

<style scoped></style>
