<template>
  <c-box>
    <!-- Asupan Sekarang -->
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Asupan Karbohidrat Sekarang (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Asupan Karbohidrat Sekarang (gram)"
          height="62px"
          v-model="currentCarbohydrateIntake"
        />
      </c-input-group>
    </c-form-control>

    <!--Kebutuhan Asupan-->
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kebutuhan Asupan Karbohidrat (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Kebutuhan Asupan Karbohidrat  (gram)"
          height="62px"
          v-model="carbohydrateIntakeNeeds"
        />
      </c-input-group>
    </c-form-control>

    <!--% Kecukupan-->
    <c-form-control is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        % Kecukupan Karbohidrat
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan % Kecukupan Karbohidrat"
          height="62px"
          paddingRight="60px"
          v-model="carbohydrateAdequancy"
        />
      </c-input-group>
    </c-form-control>

    <!--Status Karbohidrat-->
    <c-form-control v-if="withoutAdds === false" is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Status Karbohidrat
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="carbohydrateStatus"
          height="62px"
          placeholder="Masukkan Status Karbohidrat"
        >
          <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
          <option value="less_intake">Asupanmu Kurang</option>
          <option value="normal_intake">Asupanmu Normal</option>
          <option value="excessive_intake">Asupanmu Berlebih</option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Catatan-->
    <c-form-control v-if="withoutAdds === false" is-required marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Catatan Asupan Karbohidrat
      </c-form-label>
      <textarea
        rows="3"
        v-chakra
        id="proteinIntakeNotes"
        placeholder="Masukkan Catatan Asupan Karbohidrat"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="carbohydrateIntakeNotes"
      />
    </c-form-control>
  </c-box>
</template>

<script>
import equal from "fast-deep-equal";
import {
  calculateKebutuhanAsupanKarbohidrat,
  calculateKecukupanKarbohidrat,
  calculateStatus,
} from "@/utils/asupan-gizi-calculation";

export default {
  name: "karbohidrat-form",
  props: {
    kebutuhanAsupanEnergi: Number,
    kebutuhanLemak: Number,
    kebutuhanProtein: Number,
    withoutAdds: Boolean,
    value: {
      required: true,
      type: Object,
      _type: {
        currentCarbohydrateIntake: Number,
        carbohydrateIntakeNeeds: Number,
        carbohydrateAdequancy: Number,
        carbohydrateStatus: String,
        carbohydrateIntakeNotes: String,
      },
    },
  },
  data() {
    return {
      currentCarbohydrateIntake: this.value.currentCarbohydrateIntake,
      carbohydrateIntakeNeeds: this.value.carbohydrateIntakeNeeds,
      carbohydrateAdequancy: this.value.carbohydrateAdequancy,
      carbohydrateStatus: this.value.carbohydrateStatus,
      carbohydrateIntakeNotes: this.value.carbohydrateIntakeNotes,
    };
  },
  computed: {
    _karboIntakeNeeds() {
      let data = calculateKebutuhanAsupanKarbohidrat({
        kebutuhanAsupanEnergi: +this.kebutuhanAsupanEnergi,
        kebutuhanLemak: +this.kebutuhanLemak,
        kebutuhanProtein: this.kebutuhanProtein / 100,
      });

      return +(data.toFixed(2))
    },
    _karboAdequancy() {
      let data = calculateKecukupanKarbohidrat({
        asupanKarbohidratSekarang: +this.currentCarbohydrateIntake,
        kebutuhanAsupanKarbohidrat: +this._karboIntakeNeeds,
      });

      return +((data * 100).toFixed(2));
    },
    _karboStatus() {
      return calculateStatus(+this._karboAdequancy / 100);
    },
    _value() {
      return {
        currentCarbohydrateIntake: this.currentCarbohydrateIntake,
        carbohydrateIntakeNeeds: this.carbohydrateIntakeNeeds,
        carbohydrateAdequancy: this.carbohydrateAdequancy,
        carbohydrateStatus: this.carbohydrateStatus,
        carbohydrateIntakeNotes: this.carbohydrateIntakeNotes,
      };
    },
  },
  watch: {
    value(val) {
      this.currentCarbohydrateIntake = val.currentCarbohydrateIntake;
      this.carbohydrateIntakeNeeds = val.carbohydrateIntakeNeeds;
      this.carbohydrateAdequancy = val.carbohydrateAdequancy;
      this.carbohydrateStatus = val.carbohydrateStatus;
      this.carbohydrateIntakeNotes = val.carbohydrateIntakeNotes;
    },
    _value: {
      deep: true,
      handler(val, old) {
        if (equal(val, old)) return;

        let intakeNeeds = this._karboIntakeNeeds;
        let adequancy = this._karboAdequancy;
        let status = this._karboStatus;

        if (val.carbohydrateIntakeNeeds !== intakeNeeds) {
          val.carbohydrateIntakeNeeds = intakeNeeds;
        }
        if (val.carbohydrateAdequancy !== adequancy) {
          val.carbohydrateAdequancy = adequancy;
        }
        if (val.carbohydrateStatus !== status) {
          val.carbohydrateStatus = status;
        }

        this.$emit("input", val);
      },
    }
  },
};
</script>

<style scoped></style>
